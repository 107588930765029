import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Profile} from "./components/auth/Auth0";

import AuthPage from "./components/layout/AuthPage";
import NotePage from "./components/layout/NotePage";
import NotesPage from "./components/layout/NotesPage";
import RequireAuth from "./components/auth/RequireAuth";
import NoPage from "./components/layout/NoPage";
import {Layout} from './components/layout/Layout';

export default function Paths( props ) {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<NotesPage />} />
					<Route element={<RequireAuth />}>
						<Route path="notes">
							<Route path=":id" element={<NotePage />} />
							<Route index element={<NotesPage />} />
						</Route>
						<Route index element={<Profile />} />
					</Route>
					<Route path="auth" element={<AuthPage />} />
					<Route path="*" element={<NoPage />} />
				</Route>
			</Routes>
		</Router>
	)
}