import React, {useState, useEffect} from "react";

function ToggleMenu( props ) {

    const [screenWidth, setScreenWidth] = useState( window.innerWidth );

    useEffect( () => {
        const changeWidth = () => {
            setScreenWidth( window.innerWidth );
        }

        window.addEventListener( 'resize', changeWidth );

        return () => {
            window.removeEventListener( 'resize', changeWidth );
        };
    }, [] );


    return (
        <div className="centered mobileFlex">
            <button className="toggleBtn focusWhite" onClick={props.toggleNav}>{props.name}</button>
            {( props.toggleMenu || screenWidth > 500 ) && (
                <ul className="list">
                    {props.menuList}
                </ul>
            )}
        </div>
    )
}

export default ToggleMenu;