import React, { Component } from "react";
class NoPage extends Component {
  render() {
    return (
      <div style={{ height: "75vh" }} className="container valign-wrapper">
        <div className="row">
          <div className="col s12 center-align">
            <h1>
              404: Page Not Found
            </h1>
          </div>
        </div>
      </div>
    );
  }
}
export default NoPage;