import React, { useEffect } from "react";
import { LogButton } from "../auth/Auth0";
import Loading from "../parts/Loading";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function AuthPage (props){
  let { user, isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    console.log("Testing AuthPage");
    console.log("user: " + JSON.stringify(user));
    console.log("isAuthenticated: " + isAuthenticated);
    console.log("isLoading: " + isLoading);
    console.log("from: " + from);
    
  },[user, isAuthenticated, isLoading, from]);

  if(isLoading){
    return <Loading />;
  }

  if(user){
    if(from){
      <Navigate to={from} />
    }

    return <Outlet />
  }
  
  return (
    <div style={{ height: "75vh" }} className="container valign-wrapper">
      <div className="row">
        <div className="col s12 center-align">
          <h4>
            It's time you <b>forgotit.online</b>
          </h4>
          <p className="flow-text grey-text text-darken-1">
            Click below to get started
          </p>
          <br />
          <div className="col s12">
            <LogButton isAuth={false}/>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AuthPage;