import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axios from 'axios';
import {useAuth0} from '@auth0/auth0-react';
import {useModeContext} from "../../hooks/useModeContext.js";
import {useFiltersContext} from "../../hooks/useFiltersContext.js";
import {useTags} from "../../utils/getTags.js";

export function NoteComp( props ) {
    console.log( "NoteComp props: " + JSON.stringify( props ) );
    const {user} = useAuth0();
    const {mode, setMode} = useModeContext();
    const {setFilterTags} = useFiltersContext();
    const updateTags = useTags;

    const [noteData, setNoteData] = useState( {
        action: props.action,
        details: props.details,
        visibility: props.visibility ? props.visibility : 0,
        tags: props.tags
    } );

    const nav = useNavigate();
    const onChange = ( e ) => {
        setNoteData( {...noteData, [e.target.name]: e.target.value} );
        console.log( "onChange: " + JSON.stringify( noteData ) );
    };
    const onArrChange = ( e ) => {
        const arr = e.target.value.split( "," );
        console.log( "onArrChange arr: " + JSON.stringify( arr ) );
        setNoteData( {...noteData, [e.target.name]: arr} );
        console.log( "onChange: " + JSON.stringify( noteData ) );
    };


    function onUpdate( e ) {
        e.preventDefault();
        console.log( "Props onclick: " + JSON.stringify( props ) );
        console.log( "noteData: " + JSON.stringify( noteData ) );
        const updateNote = async () => {
            return axios.put( '/api/notes/' + props.id, {
                action: noteData.action,
                details: noteData.details,
                tags: noteData.tags
            } )
                .then( response => {
                    console.log( response );
                    // If successful update then redirect to view of update note
                    if ( response.status === 200 ) {
                        updateTags( user );
                        setMode( "view" );
                        nav( "/notes/" );
                    } else {
                        alert( "Status: " + response.status + "\n\n" + response.body );
                    }
                } );
        };
        updateNote();
    };

    function onCreate( e ) {
        e.preventDefault();
        console.log( "Props onCreate: " + JSON.stringify( props ) );
        const createNote = async () => {
            return axios.post( '/api/notes/new', {
                useremail: user.email,
                action: noteData.action,
                details: noteData.details,
                tags: noteData.tags
            } )
                .then( response => {
                    console.log( response );
                    // If successful update then redirect to view of new note
                    if ( response.status === 200 ) {
                        console.log( JSON.stringify( response ) );
                        updateTags( user );
                        setMode( "view" );
                        nav( "/" );
                        /*setMode("view");
                        navView("/notes/" + props.id);*/
                    }
                } )
                .catch( function ( err ) {
                    if ( err ) {
                        alert( "Error:\n\n" + JSON.stringify( err.message ) );
                    }
                } );
        };
        createNote();
    };

    function onDelete( e ) {
        e.preventDefault();
        console.log( "Props onclick: " + JSON.stringify( props ) );
        console.log( "noteData: " + JSON.stringify( noteData ) );
        const deleteNote = async () => {
            return axios.delete( '/api/notes/' + props.id, {
                action: noteData.action,
                details: noteData.details,
                tags: noteData.tags
            } )
                .then( response => {
                    console.log( response );
                    // If successful update then redirect to view of update note
                    if ( response.status === 200 ) {
                        setMode( "view" );
                        nav( "/notes/" );
                        console.log( "setting mode" );
                    } else {
                        alert( "Status: " + response.status + "\n\n" + response.body );
                    }
                } );
        };
        deleteNote();
    };

    if ( !mode || mode === "view" ) {
        console.log( "NoteCompView props: " + JSON.stringify( props ) );
        console.log( 'noteData: ' + JSON.stringify( noteData ) );
        let tagsBlock = noteData.tags.map( function ( tag, index ) {
            return <span key={tag}>{( index ? <>, </> : null )}<Link key={tag} to="/" onClick={() => {setFilterTags( [tag] )}}>{tag}</Link></span>
        } );

        return (
            <>
                <div className="noteview">
                    <h3>{noteData.action}</h3>
                    <p>{noteData.details}</p>
                    <p>{noteData.visibility}</p>
                    {tagsBlock}
                </div>
                <div>
                    <Link
                        className="btn waves-effect waves-light hoverable blue accent-3"
                        to={`/notes/` + props.id}
                        onClick={() => {
                            setMode( "edit" );
                            console.log( "setting mode" );
                        }}>
                        Edit
                    </Link>
                </div>
            </>
        )
    }

    // Note visibility dropdown input options
    const visibilityOpts = [<option id="0" key="T" value="Private" />, <option id="1" key="F" value="Public" />];

    if ( mode === "edit" ) {
        console.log( "NoteCompEdit props: " + JSON.stringify( props ) );
        return (
            <>
                <form noValidate className="flex-container-col">
                    <label>
                        Action
                        <input name="action" value={noteData.action} onChange={( e ) => onChange( e )} />
                    </label>
                    <label>
                        Details
                        <input name="details" value={noteData.details} onChange={( e ) => onChange( e )} />
                    </label>
                    <label>
                        Visibility
                        <select name="visibility" value={noteData.visibility} onChange={( e ) => onChange( e )}>
                            {visibilityOpts.map( ( opt ) => {
                                return opt.value;
                            } )}
                        </select>
                    </label>
                    <label>
                        Tags (comma separated)
                        <input name="tags" value={noteData.tags} onChange={( e ) => onArrChange( e )} />
                    </label>
                </form>
                <span>
                    <button
                        className="btn waves-effect waves-light hoverable blue accent-3"
                        onClick={( e ) => {
                            onUpdate( e );
                        }}>
                        Save
                    </button>
                </span>
                <span>
                    <button
                        className="btn waves-effect waves-light hoverable blue accent-3"
                        onClick={( e ) => {
                            onDelete( e );
                        }}>
                        Delete
                    </button>
                </span>
            </>
        )
    }

    if ( mode === "create" ) {
        console.log( "NoteCompCreate props: " + JSON.stringify( props ) );

        return (
            <form className="flex-container-col">
                <label>
                    Action
                    <input name="action" type="text" onChange={( e ) => onChange( e )} />
                </label>
                <label>
                    Details
                    <input name="details" type="text" onChange={( e ) => onChange( e )} />
                </label>
                <label>
                    Visibility
                    <select name="visibility" value={noteData.visibility} onChange={( e ) => onChange( e )} >
                        {
                            visibilityOpts.map( ( option ) => (
                                <option id={option.id} value={option.value}>{option.label}</option>
                            ) )
                        }
                    </select>
                </label>
                <label>
                    Tags
                    <input name="tags" type="text" onChange={( e ) => onArrChange( e )} />
                </label>
                <button className="btn waves-effect waves-light hoverable blue accent-3" onClick={( e ) => onCreate( e )}>Save</button>
            </form>
        )
    }
}