import {UserContext} from "../context/UserContext";
import {useContext} from "react";
import {useAuth0} from "@auth0/auth0-react";
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';


export const useUserContext = () => {
    const context = useContext( UserContext );
    const {getAccessTokenSilently} = useAuth0();

    if ( !context.token.userToken ) {
        const userToken = getAccessToken( getAccessTokenSilently );
        setAuthToken( userToken );
        context.token.setUserToken( userToken );
    }

    if ( !context ) {
        throw Error( "useUserContext must be used inside a UserProvider" );
    }

    return context;
}

async function getAccessToken( getAccessTokenSilently ) {
    const token = await getAccessTokenSilently( {
        audience: 'https://api.example.com/',
        scope: 'read:posts',
    } );
    return token;
}