import React, {useState, useEffect, useMemo, useCallback} from "react";
import {Link} from "react-router-dom";
import {useModeContext} from "../../hooks/useModeContext";
import {useFiltersContext} from "../../hooks/useFiltersContext";
import {useUserContext} from "../../hooks/useUserContext";
import {LogLink} from "../auth/Auth0";
import {useAuth0} from "@auth0/auth0-react";
import ToggleMenu from "./ToggleMenu";
import axios from "axios";

function Navbar( props ) {
  const {setMode} = useModeContext();
  const {setFilterTags} = useFiltersContext();
  const {tags} = useUserContext();

  const {user, isAuthenticated} = useAuth0();
  const [toggleMenus, setToggleMenus] = useState( {
    tagsMenu: false,
    actionsMenu: false
  } );
  const toggleNav = ( menuId ) => {
    setToggleMenus( {...toggleMenus, [menuId]: !toggleMenus[menuId]} );
  };

  console.log( "tags" );

  useEffect( () => {
    const getTags = async () => {
      const response = await axios.post( '/api/notes/tags', {useremail: user.email} );
      const data = response.data || [];
      console.log( "tags check" );

      if ( JSON.stringify( tags.userTags ) !== JSON.stringify( data ) ) {
        console.log( "Not matching" );
        tags.setUserTags( data );
      }
    };
    if ( user ) {
      getTags();
      return;
    }
    console.log("Navbar useEffect no user");
  }, [tags, user] );

  const tagsMenuArr = useMemo( () => {
    let arr = [];
    console.log("isAuthenticated: " + isAuthenticated);
    
    if ( isAuthenticated ) {
      for ( var tag in tags.userTags ) {
        let tagStr = tags.userTags[tag];
        arr.push(
          <li key={tagStr} className="items">
            <span key={tagStr}>
              <Link className="menu-link" to="/" onClick={() => {setFilterTags( [tagStr] )}}>
                {tagStr}
              </Link>
            </span>
          </li>
        )
      }
    }
    return arr;
  }, [isAuthenticated, setFilterTags, tags.userTags] );

  const actionsMenuArr = [];
  isAuthenticated && (
    actionsMenuArr.push(
      <li key="createNote" className="items">
        <Link
          to="/"
          onClick={() => {
            setToggleMenus( {actionsMenu: false, tagsMenu: false} );
            setFilterTags( [] );
            setMode( "create" );
          }}
          style={{
            fontFamily: "monospace"
          }}
          className="menu-link">
          Create Note
        </Link>
      </li>
    )
  );

  actionsMenuArr.push(
    <li key="logLink" className="items">
      <LogLink isAuth={isAuthenticated} />
    </li>
  );

  return (
    <nav className="white menu">
      <ul className="list centered">
        <li className="items childPadding">
          <Link
            to="/"
            onClick={() => {
              setToggleMenus( {tagsMenu: false, actionsMenu: false} );
              setFilterTags( [] );
              setMode( "view" );
            }}
            style={{
              fontFamily: "monospace",
              border: "none"
            }}
            className="black-text menu childPadding mobileWidth centered"
          >
            <i className="material-icons">code</i>
            <div>
              ForgotIt.Online
            </div>
          </Link>
        </li>
        <li className="items">
          <ToggleMenu id="actionsMenu" name="Actions" toggleMenu={toggleMenus.actionsMenu} toggleNav={() => toggleNav( "actionsMenu" )} setToggleMenus={setToggleMenus} menuList={actionsMenuArr} />
          <ToggleMenu id="tagsMenu" name="Tags" toggleMenu={toggleMenus.tagsMenu} toggleNav={() => toggleNav( "tagsMenu" )} setToggleMenus={setToggleMenus} menuList={tagsMenuArr} />
        </li>
      </ul>
    </nav>
  );
}
export default Navbar;