import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export function LogButton(props){
    const state = { pathname: "/" };

    if(!props){
        return;
    }

    if(props.isAuth){
        return LogoutButton();
    }

    return LoginButton(state);
}

export function LogLink(props){
    const state = { pathname: "/" };

    if(!props){
        return;
    }

    if(props.isAuth){
        return LogoutLink();
    }

    return LoginLink(state);
}

function LoginButton(state) {
    const { loginWithRedirect } = useAuth0();
    return <button className="btn btn-large waves-effect waves-light hoverable blue accent-3" onClick={async () =>
        await loginWithRedirect({redirect_uri: state.pathname})}>
        Login / Register
    </button>;
};

function LogoutButton() {
    const { logout } = useAuth0();
    return (
        <button className="btn btn-large waves-effect waves-light hoverable blue accent-3" onClick={() =>
            logout({ returnTo: window.location.origin })
        }>
            Log Out
        </button>
    );
};

function LoginLink(state) {
    const { loginWithRedirect } = useAuth0();
    return (
    <>
        <button
            className="focusWhite"
            onClick={async () =>
            await loginWithRedirect({redirect_uri: state.pathname})}>
            Login / Register
        </button>
    </>
    );
};

function LogoutLink() {
    const { logout } = useAuth0();
    return (
        <>
            <button
                className="focusWhite"
                onClick={() =>
                logout({ returnTo: window.location.origin })
            }>
                Log Out
            </button>
        </>
    );
};

export function Profile() {
    const { user, isAuthenticated, isLoading } = useAuth0();
    if (isLoading) {
        return <div>Loading ...</div>;
    }

    return (
        isAuthenticated && (
            <div>
                <img src={user.picture} alt={user.name} />
                <h2>{user.name}</h2>
                <p>{user.email}</p>
            </div>
        )
    );
};