import React from "react";
import {useFiltersContext} from "../../hooks/useFiltersContext";

export default function NotesFilter( props ) {
  const {filterTags, setFilterTags} = useFiltersContext();
  const setFilter = ( e ) => {
    const fieldVal = e.target.value;
    const strLength = fieldVal.length;
    const lastChar = fieldVal[strLength - 1];
    console.log( "lastChar: " + lastChar );

    //if ( lastChar === "" || lastChar === "," || lastChar === " " ) {return }

    const tags = e.target.value.split( "," );
    console.log( "tags: " + JSON.stringify( tags ) );
    setFilterTags( tags );
  };

  return (
    <>
      <div>
        <label>
          Filter tags (comma separated)
          <input name="tagsFilter" value={filterTags} onChange={( e ) => {setFilter( e )}} />
          <button name="filterClearBtn" onClick={() => {setFilterTags( [] )}}>Clear</button>
        </label>
      </div>
    </>
  )
}