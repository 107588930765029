import React from 'react';
import {Outlet} from "react-router-dom";
import Navbar from './Navbar';
import {UserProvider} from '../../context/UserContext';
import {ModeProvider} from '../../context/ModeContext.js';
import {FiltersProvider} from '../../context/FiltersContext';

export function Layout( {children} ) {
    return (
        <>
            <UserProvider>
                <ModeProvider>
                    <FiltersProvider>
                        <div>
                            <div>
                                <Navbar />
                            </div>
                            <div className="container">
                                <Outlet />
                            </div>
                        </div>
                    </FiltersProvider>
                </ModeProvider>
            </UserProvider>
        </>
    )
}