import {ModeContext} from "../context/ModeContext";
import {useContext} from "react";

export const useModeContext = () => {
    const context = useContext( ModeContext );

    if ( !context ) {
        throw Error( "useModeContext must be used inside a ModeProvider" );
    }

    return context;
}