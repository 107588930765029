import React, {useEffect, useCallback, useMemo} from "react";
import ErrorBoundary from "./ErrorBoundary";
import {NoteComp} from "./NoteComp";
import {useFiltersContext} from "../../hooks/useFiltersContext";

function Notes( props ) {
  console.log( "notes: " + JSON.stringify( props ) );
  const {filterTags} = useFiltersContext();

  const notes = useMemo( () => {
    let listNotes = [];
    listNotes = props.notes.map( ( note ) => {
      // If filters set, only show notes with any matching filters
      if ( filterTags && filterTags.length > 0 ) {
        // Checks for any tags that "start with" partial match
        const tagCheck = ( tag ) => {
          console.log( "tagCheck tag: " + tag );

          const startsWith = filterTags.find( ( fTag ) => {
            console.log( "fTag: " + fTag );
            console.log( "tag.indexOf(fTag): " + tag.indexOf( fTag ) );
            const trimmedTag = fTag.trim();
            return tag.indexOf( trimmedTag ) === 0;
          } );

          if ( startsWith != null ) {
            return true;
          }
          return false;
        };
        // Skip if note has no tags (e.g. legacy data)
        if ( !note.tags ) {return null}
        console.log( "note.tags: " + JSON.stringify( note.tags ) );

        const includesTag = note.tags.some( tagCheck );
        // Skip if note does not include tag
        if ( !includesTag ) {return null}
      }

      return (
        <li key={note._id}>
          <ErrorBoundary>
            <NoteComp action={note.action} details={note.details} tags={note.tags} id={note._id} />
          </ErrorBoundary>
        </li>
      )
    } );
    console.log( "listNotes: " + listNotes );

    return (
      <div>
        <ul>{listNotes}</ul>
      </div>
    );
  }, [filterTags, props.notes] );
  return notes;
}
export default Notes;