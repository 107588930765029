import React, { useState } from "react";

const ModeContext = React.createContext({
        mode: "view",
        setMode: () => { }
    });
console.log(ModeContext);

function ModeProvider ({ children }) {
    const [mode, setMode] = useState("view");
    return (
        <ModeContext.Provider value={{ mode, setMode }}>
            {children}
        </ModeContext.Provider>
    )
};

export { ModeContext, ModeProvider };