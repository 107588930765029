import React, { useEffect } from "react";
import Cookies from "js-cookie";

import { Provider } from "react-redux";
import { store } from "./store";

import Loading from "./components/parts/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import Paths from "./Paths";

export default function App() {
	const { user, isAuthenticated, isLoading } = useAuth0();

	Cookies.set("user", user);
	Cookies.set("isAuthenticated", isAuthenticated);

	useEffect(() => {
		if(!isLoading){
			Cookies.set("user", user);
			Cookies.set("isAuthenticated", isAuthenticated);
		}
	}, [user, isAuthenticated, isLoading]);

	/*useEffect(() => {
		console.log("User Change");
		console.log("user: " + JSON.stringify(user));
		console.log("isAuthenticated: " + isAuthenticated);

		if(user){
			return <Navigate to="/" state= {{pathname: "/", emailVerified: user.email_verified}}/>;
		}
	}, [user, isAuthenticated]);*/

	if(isLoading){
		return (
			<Loading/>
		)
	}
	return (
		<div className="App">
			<Provider store={store}>
				<Paths />
			</Provider>
		</div>
	);
}