import React, {useState, useEffect, useMemo} from 'react';
import axios from 'axios';
import Notes from '../parts/Notes.js';
import NotesFilter from '../parts/NotesFilter.js';
import {useModeContext} from "../../hooks/useModeContext.js";
import {useFiltersContext} from "../../hooks/useFiltersContext.js";
import {useAuth0} from '@auth0/auth0-react';

function NotesPage( props ) {
    const emptyNote = useMemo( () => {
        return {_id: 0, action: 'Loading...', details: '', tags: []};
    }, [] );
    const [notes, setNotes] = useState( [emptyNote] );
    const {user} = useAuth0();
    const {mode} = useModeContext();
    const {filterTags} = useFiltersContext();
    console.log( "User:" + user );
    const userEmail = user && user.email ? user.email : "";

    console.log( "NotesPage props: " + JSON.stringify( props ) );
    if ( user ) {
        console.log( "NotesPage user: " + JSON.stringify( user ) );
    }

    useEffect( () => {
        const updateNotes = async () => {
            const postData = {
                useremail: userEmail
            };

            const response = await axios.post( '/api/notes', postData );
            const data = response.data || [];

            console.log( "fetch notes: " + JSON.stringify( data ) );

            setNotes( data );
        };

        if ( mode === "create" ) {
            setNotes( [emptyNote] );
            return;
        }

        updateNotes();

    }, [mode, userEmail, emptyNote] );

    useEffect( () => {
        console.log( "filterTags: " + JSON.stringify( filterTags ) );
    }, [filterTags] );

    return (
        <>
            {( !mode || mode === "view" ) && (
                <NotesFilter />
            )}
            <Notes notes={notes} />
        </>
    )
}
export default NotesPage;