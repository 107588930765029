import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Navigate, Outlet, useLocation} from 'react-router-dom';

function RequireAuth( {children, redirectTo} ) {
    const {user, isAuthenticated, isLoading} = useAuth0();
    const location = useLocation();

    console.log( "user: " + user + " - isLoading: " + isLoading + " - isAuthenticated: " + isAuthenticated );

    if ( !user ) {
        return <Navigate to="/auth" state={{from: location, isLoading: isLoading}} />;
    }

    return <Outlet />
}

export default RequireAuth;