import axios from "axios";
import {useUserContext} from "../hooks/useUserContext";
import {useAuth0} from '@auth0/auth0-react';

export async function useTags() {
    const {user} = useAuth0();
    const {tags} = useUserContext();
    const response = await axios.post( "/api/notes/tags", {useremail: user.email} );
    const data = response.data || [];
    console.log( "data: " + JSON.stringify( data ) );
    console.log( "tags.userTags: " + tags.userTags )

    if ( JSON.stringify( tags.userTags ) !== JSON.stringify( data ) ) {
        console.log( "Not matching" );
        tags.setUserTags( data );
    }
};