import { createSlice } from '@reduxjs/toolkit';
import setAuthToken  from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';


const isEmpty = require('is-empty');

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  errors: {}
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers:{
    SET_CURRENT_USER:(state, action) => {
      state.isAuthenticated = !isEmpty(action.payload);
      state.user = action.payload;
    },
    USER_LOADING:(state, action) => {
      const userLoad = !state.loading;
      state.loading = userLoad;
    },
    GET_ERRORS:(state, action) => {
      if(action.payload){
      state.errors = action.payload;
      }else{
        state.errors = {};
      }
    }
  }
});

export const { SET_CURRENT_USER, USER_LOADING, SET_ERRORS } = authSlice.actions;

export default authSlice.reducer;