import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom';
import axios from 'axios';
import Notes from '../parts/Notes';
import {useAuth0} from '@auth0/auth0-react';

function NotePage( props ) {
    const [note, setNote] = useState( {_id: 0, action: 'Loading...', details: '', tags: []} );
    const {id} = useParams();
    const {user} = useAuth0();

    console.log( "NotePage: " + id );
    console.log( "props: " + JSON.stringify( props ) );

    useEffect( () => {
        const fetchNote = async () => {
            const response = await axios.post( '/api/notes/' + id, {useremail: user.email} );
            console.log( 'test - ' + JSON.stringify( response.data ) );
            const data = response.data;
            setNote(
                {_id: data._id, action: data.action, details: data.details, tags: data.tags}
            );
        };

        fetchNote();
    }, [id, user.email] );

    if ( id ) {
        return (
            <>
                <Notes notes={[note]} />
            </>
        );
    } else {
        return (
            <>
                <Notes notes={[note]} />
            </>
        );
    }
}
export default NotePage;