import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers:{
    GET_ERRORS:(state, action) => {
      if(action.payload){
      state.errors = action.payload;
      }else{
        state.errors = {};
      }
    }
  }
});

export const { GET_ERRORS } = errorSlice.actions;
export default errorSlice.reducer;