import React, {useState} from "react";

const FiltersContext = React.createContext( {
    filterTags: [],
    setFilterTags: () => {}
} );
console.log( FiltersContext );

function FiltersProvider( {children} ) {
    const [filterTags, setFilterTags] = useState( [] );
    return (
        <FiltersContext.Provider value={{filterTags, setFilterTags}}>
            {children}
        </FiltersContext.Provider>
    )
};

export {FiltersContext, FiltersProvider};