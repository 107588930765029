import React, {useState} from "react";

const UserContext = React.createContext( {
    tags: {
        userTags: [],
        setUserTags: () => {}
    },
    token: {
        userToken: '',
        setUserToken: () => {}
    }
} );

console.log( UserContext );

function UserProvider( {children} ) {
    const [userTags, setUserTags] = useState( [] );
    const [userToken, setUserToken] = useState( [] );

    const user = {
        tags: {
            userTags,
            setUserTags
        },
        token: {
            userToken,
            setUserToken
        }
    };

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    )
};

export {UserContext, UserProvider};